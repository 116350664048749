var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('vs-table',{attrs:{"max-items":"10","pagination":"","data":_vm.masterMerge},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(item){return _c('vs-tr',{key:item.spoc_name},[_c('vs-td',{attrs:{"data":item.spoc_name}},[_c('div',[_vm._v(_vm._s(item.date))])]),_c('vs-td',{attrs:{"data":item.call_utilization}},[_c('div',[_vm._v(_vm._s(item.call_utilization))])]),_c('vs-td',{attrs:{"data":item.visitor_utilization}},[_c('div',[_vm._v("\n            "+_vm._s(item.visitor_utilization)+" |\n            "+_vm._s(item.visit_engagements.length)+"\n          ")])]),_c('vs-td',[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-enrollment', [
                  item.enrollments,
                  item.enrollments.length,
                  item.spoc_name ])}}},[_vm._v(_vm._s(item.enrollments.length))])])]),_c('vs-td',{attrs:{"data":item.total_utilization}},[_c('vs-chip',{attrs:{"color":item.total_color}},[_vm._v(_vm._s(item.total_utilization))])],1),_c('vs-td',{attrs:{"data":item.total_calls}},[_c('div',[_vm._v("\n            "+_vm._s(item.total_calls)+" | "+_vm._s(item.total_unique_calls)+" |\n            "+_vm._s(item.total_duration)+" | "+_vm._s(item.incoming_call_count)+" |\n            "+_vm._s(item.outgoing_call_count)+"\n          ")])]),_c('vs-td',{attrs:{"data":item.connected_calls}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                  item.connected_calls_arr,
                  item.spoc_name,
                  item.connected_calls ])}}},[_vm._v(_vm._s(item.connected_calls))])])]),_c('vs-td',{attrs:{"data":item.mwb_call_count}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                  item.mwb_arr,
                  item.spoc_name,
                  item.mwb_call_count ])}}},[_vm._v(_vm._s(item.mwb_call_count))]),_vm._v("\n            ("+_vm._s(item.mwb_connected_call_count)+")|\n            "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-mwb', [
                  item.mwb_connected_calls_arr,
                  item.spoc_name,
                  item.mwb_connected_calls ])}}},[_vm._v(_vm._s(item.mwb_connected_calls))]),_vm._v("|"+_vm._s(item.total_mwb_unique_calls)+"\n          ")])]),_c('vs-td',{attrs:{"data":item.ewb_call_count}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-ewb', [
                  item.ewb_arr,
                  item.spoc_name,
                  item.ewb_call_count ])}}},[_vm._v(_vm._s(item.ewb_call_count)+"\n            ")]),_vm._v("\n            ("+_vm._s(item.ewb_connected_call_count)+") |\n            "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-ewb', [
                  item.ewb_connected_calls_arr,
                  item.spoc_name,
                  item.ewb_connected_calls ])}}},[_vm._v(_vm._s(item.ewb_connected_calls))]),_vm._v("|"+_vm._s(item.total_ewb_unique_calls)+"\n          ")])]),_c('vs-td',{attrs:{"data":item.call_30_60}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-long')}}},[_vm._v(_vm._s(item.call_30_60))]),_vm._v("\n            |\n            "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-long')}}},[_vm._v(_vm._s(item.call_gt_60))])])]),_c('vs-td',{attrs:{"data":item.nuc_count}},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.EmitEvent('utilization-nuc', [
                  item.nuc_arr,
                  item.spoc_name,
                  item.nuc_count ])}}},[_vm._v(_vm._s(item.nuc_count))]),_vm._v("\n            | "+_vm._s(item.nuc_duration)+"\n          ")])]),_c('vs-td',[_c('div',[_vm._v("\n            "+_vm._s(_vm.giveAttendanceTitle(item))+"\n          ")])])],1)})}}])},[_c('template',{staticClass:"exc",slot:"thead"},[_c('vs-th',[_vm._v("Date")]),_c('vs-th',[_vm._v("Utilization(%)")]),_c('vs-th',[_vm._v("Visitors(%)")]),_c('vs-th',[_vm._v("Enrollments")]),_c('vs-th',[_vm._v("Total")]),_c('vs-th',[_vm._v("Total Calls|Total Unique Calls|Duration|Incoming|Outgoing")]),_c('vs-th',[_vm._v("Connected Calls")]),_c('vs-th',[_vm._v("MWB Call Count|Connected Calls|Total Unique Calls")]),_c('vs-th',[_vm._v("EWB Call Count|Connected Calls|Total Unique Calls")]),_c('vs-th',[_vm._v("Long Duration Calls 30-60 Mins | > 60 Mins")]),_c('vs-th',[_vm._v("Total NUC | Duration")]),_c('vs-th',[_vm._v("Attendance")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }